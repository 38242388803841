import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Obfuscate from "react-obfuscate"
import nl2br from "react-nl2br"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      contactDetails: craftContactGlobalSet {
        emailAddress
        physicalAddress: textPrimary
        postalAddress: textSecondary
      }
      navLinks: allCraftFooterMenuDefaultEntry {
        nodes {
          id
          title
          link: buttonLink {
            uri
          }
        }
      }
      footerDetails: craftFooterGlobalSet {
        privacyPolicyLink: buttonLink {
          title
          uri
        }
        logos {
          ... on Craft_logos_logoItem_BlockType {
            id
            link: buttonLink
            image {
              ... on Craft_images_Asset {
                id
                title
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  const { contactDetails, navLinks } = data
  const { privacyPolicyLink, logos } = data.footerDetails
  const currentYear = new Date().getFullYear()

  return (
    <footer className="-mt-12 md:-mt-24 relative">
      <div className="mt-12 md:mt-24 bg-grey absolute inset-0"></div>
      <div className="container relative">
        <div className="md:grid md:grid-cols-12 md:gap-5">
          <div className="col-span-6 lg:col-span-5">
            <div className="h-full -ml-ocs py-12 pr-8 space-y-6 md:py-20 md:pr-12 md:space-y-9 pl-ocs bg-green rounded-tr-3xl text-white">
              {contactDetails && (
                <ul className="space-y-6 md:space-y-9 text-sm xs:text-lg sm:text-xl xl:text-2xl">
                  {contactDetails.emailAddress && (
                    <li>
                      <h6 className="text-xs xs:text-sm sm:text-base xl:text-lg">
                        Email
                      </h6>
                      <Obfuscate
                        className="link"
                        email={contactDetails.emailAddress}
                      />
                    </li>
                  )}
                  {contactDetails.postalAddress && (
                    <li>
                      <h6 className="text-xs xs:text-sm sm:text-base xl:text-lg">
                        Postal Address
                      </h6>
                      {nl2br(contactDetails.postalAddress)}
                    </li>
                  )}
                  {contactDetails.physicalAddress && (
                    <li>
                      <h6 className="text-xs xs:text-sm sm:text-base xl:text-lg">
                        Address
                      </h6>
                      {nl2br(contactDetails.physicalAddress)}
                    </li>
                  )}
                </ul>
              )}
              <p className="text-xs xs:text-sm lg:text-base xl:text-lg">
                &copy; Copyright {currentYear} NZHIH
                {privacyPolicyLink.length && (
                  <>
                    <span className="hidden xs:inline">&nbsp;&#124;&nbsp;</span>
                    <Link
                      className="block mt-2 text-white underline hover:no-underline xs:inline xs:mt-0"
                      to={`/${privacyPolicyLink[0].uri}/`}
                    >
                      {privacyPolicyLink[0].title}
                    </Link>
                  </>
                )}
              </p>
            </div>
          </div>
          <div className="col-start-7 col-span-6">
            <div className="py-12 md:mt-24">
              {navLinks.nodes.length && (
                <ul className="space-y-3 text-2xl">
                  {navLinks.nodes.map(navLink => (
                    <li key={navLink.id}>
                      <Link
                        className="text-black hover:text-opacity-50"
                        to={`/${navLink.link[0].uri}/`}
                      >
                        {navLink.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
              {logos.length && (
                <ul className="flex gap-x-5 h-12 mt-10 lg:gap-x-10 lg:mt-12">
                  {logos.map(logo => {
                    const { id, link, image } = logo
                    const title = image[0].title || ""

                    return (
                      <li key={id} className="h-full">
                        {link !== "" ? (
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              className="h-full object-contain"
                              src={image[0].localFile.publicURL}
                              alt={title}
                            />
                          </a>
                        ) : (
                          <img
                            className="h-full object-contain"
                            src={image[0].localFile.publicURL}
                            alt={title}
                          />
                        )}
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
