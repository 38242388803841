import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import MobileMenu from "../MobileMenu/MobileMenu"
import Logo from "../SVG/Logo"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      navLinks: allCraftMainMenuDefaultEntry(filter: { level: { eq: 1 } }) {
        nodes {
          id
          title
          translatedTitle
          link: buttonLink {
            uri
          }
          remoteChildren {
            ... on Craft_mainMenu_default_Entry {
              id
              title
              translatedTitle
              link: buttonLink {
                uri
              }
            }
          }
        }
      }
      craftHeaderGlobalSet {
        ctaLink: buttonLink {
          uri
        }
        ctaText: textPrimary
      }
    }
  `)

  const { navLinks } = data
  const { ctaLink, ctaText } = data.craftHeaderGlobalSet

  return (
    <header className="h-20 bg-green sticky top-0 z-10 text-white lg:h-auto lg:static">
      <div className="container flex justify-between h-full">
        <div className="flex-1 flex justify-between items-center h-full py-2 lg:pt-14 lg:pb-12 lg:mr-15 xl:mr-20">
          <div className="mr-12">
            <Link
              className="inline-block w-12 align-top lg:w-auto lg:h-16 xl:h-24"
              to="/"
            >
              <Logo />
            </Link>
          </div>

          <MobileMenu items={navLinks.nodes} cta={{ctaLink, ctaText}} />

          {navLinks.nodes.length && (
            <nav className="hidden lg:block">
              <ul className="flex gap-x-9 xl:gap-x-12">
                {navLinks.nodes.map(navLink => (
                  <li className="group relative z-10" key={navLink.id}>
                    <Link
                      className="block font-bold tracking-wider leading-4 text-xs transition hover:text-black xl:text-sm 2xl:text-base"
                      partiallyActive="text-black"
                      activeClassName="text-black"
                      to={`/${navLink.link[0].uri}/`}
                    >
                      {navLink.title}
                      <div className="font-normal opacity-75">
                        {navLink.translatedTitle}
                      </div>
                    </Link>
                    {navLink.remoteChildren.length > 0 && (
                      <div className="pt-4 invisible opacity-0 absolute left-0 transition group-hover:visible group-hover:opacity-100">
                        <div className="relative">
                          <ul className="p-2 bg-white text-black rounded-br-xl text-sm">
                            {navLink.remoteChildren.map(subLink => (
                              <li key={subLink.id}>
                                <Link
                                  to={`/${subLink.link[0].uri}/`}
                                  className="block p-2 whitespace-nowrap transition hover:text-green"
                                >
                                  {subLink.title}
                                  <div className="font-normal text-xs italic opacity-75">
                                    {subLink.translatedTitle}
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                          <div className="w-2 h-2 bg-white absolute top-0 left-4 transform -translate-y-1/2 rotate-45"></div>
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
        {ctaLink && ctaText && (
          <Link
            className="hidden lg:inline-flex items-center h-15 -mr-ocs px-14 bg-white rounded-bl-xl text-base uppercase tracking-wider text-black transition hover:text-green"
            to={`/${ctaLink[0].uri}/`}
          >
            {ctaText}
          </Link>
        )}
      </div>
    </header>
  )
}

export default Header
