import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./MobileMenu.css"

const MobileMenu = ({ items, cta }) => {
  const [isOpen, setIsOpen] = useState(false)
  const overlayRef = useRef()
  const {ctaText, ctaLink} = cta

  useEffect(() => {
    const documentClassList = document.documentElement.classList

    if (isOpen) {
      documentClassList.add("overflow-hidden", "lg:overflow-auto")
    } else {
      documentClassList.remove("overflow-hidden", "lg:overflow-auto")
    }

    return () => {
      documentClassList.remove("overflow-hidden", "lg:overflow-auto")
    }
  }, [isOpen])

  const toggleClickHandler = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  const overlayClickHandler = e => {
    if (e.target === overlayRef.current) {
      setIsOpen(false)
    }
  }

  if (!items.length) return null

  return (
    <div className="lg:hidden">
      <button
        className={`MobileMenu__toggle${
          isOpen ? " MobileMenu__toggle--active" : ""
        }`}
        onClick={toggleClickHandler}
        type="button"
      >
        <div className="MobileMenu__toggle__line"></div>
        <div className="MobileMenu__toggle__line"></div>
        <div className="MobileMenu__toggle__line"></div>
      </button>

      <div
        className={`fixed inset-0 z-10 bg-black bg-opacity-80 transition-all duration-500${
          !isOpen ? "opacity-0 invisible" : "opacity-1 visible"
        }`}
        ref={overlayRef}
        onClick={overlayClickHandler}
      >
        <nav className="h-full max-w-full pt-20 pr-ocs bg-white absolute top-0 right-0 text-black">
          <div className="w-80 h-full max-w-full border-t border-r border-grey rounded-tr-xl overflow-y-auto">
            <ul className="border-b border-grey text-lg divide-y divide-grey">
              {items.map(item => (
                <li key={item.id} className="flex flex-wrap">
                  <Link
                    className="flex-1 block px-4 py-2"
                    partiallyActive="bg-grey"
                    activeClassName="bg-grey"
                    to={`/${item.link[0].uri}/`}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
              <li key={cta.ctaText} className="flex flex-wrap">
                <Link
                  className="flex-1 block px-4 py-2"
                  partiallyActive="bg-grey"
                  activeClassName="bg-grey"
                  to={`/${ctaLink[0].uri}/`}
                >
                  {ctaText}
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

MobileMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      translatedTitle: PropTypes.string,
      link: PropTypes.arrayOf(
        PropTypes.shape({
          uri: PropTypes.string,
        })
      ),
      remoteChildren: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          translatedTitle: PropTypes.string,
          link: PropTypes.arrayOf(
            PropTypes.shape({
              uri: PropTypes.string,
            })
          ),
        })
      ),
    })
  ),
}

export default MobileMenu
